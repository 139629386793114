import { EMAIL_REGEX, PHONE_REGEX } from 'app/config/constants';
import { Validations, ValidationUtils } from 'app/shared/util/validation-utils';
import { isCEP, isCNPJ, isCPF } from 'brazilian-values';
import { unmask } from 'app/shared/util/mask-utils';
import StringUtils from 'app/shared/util/string-utils';

const EMAIL_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.email.required',
  },
  format: {
    predicate: it => !!it && EMAIL_REGEX.test(it),
    errorMessage: 'validation.common.email.format',
  },
  max_length: {
    predicate: it => !it?.length || it.length <= 254,
    errorMessage: 'validation.common.email.maxLength',
  },
};
export const validateEmail = (value: string) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const PERSON_NAME_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.generic.required',
  },
  format: {
    predicate: it => !!it && StringUtils.isFullName(it),
    errorMessage: 'validation.common.fullName.format',
  },
  max_length: {
    predicate: it => !it?.length || it.length <= 255,
    errorMessage: 'validation.common.generic.maxLength',
  },
};
export const validatePersonName = (value: string) => ValidationUtils.validate(value, PERSON_NAME_VALIDATION);

const PASSWORD_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.passwordField.required',
  },
  min_length: {
    predicate: it => !!it?.length && it.length >= 4,
    errorMessage: 'validation.common.passwordField.minLength',
  },
  max_length: {
    predicate: it => !it?.length || it.length <= 100,
    errorMessage: 'validation.common.passwordField.maxLength',
  },
};
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
  password: string;
  match: string;
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch> = {
  required: {
    predicate: it => !!it && !!it.match,
    errorMessage: 'validation.common.confirmField.required',
  },
  min_length: {
    predicate: it => !!it?.match?.length && it.match.length >= 4,
    errorMessage: 'validation.common.confirmField.minLength',
  },
  max_length: {
    predicate: it => !it?.match?.length || it.match.length <= 100,
    errorMessage: 'validation.common.confirmField.maxLength',
  },
  match: {
    predicate: it => !!it && !!it.password && !!it.match && it.password === it.match,
    errorMessage: 'validation.common.confirmField.match',
  },
};

export const validatePasswordMatch = (value: PasswordMatch) => ValidationUtils.validate(value, PASSWORD_MATCH_VALIDATION);

const PHONE_NUMBER_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.phone.required',
  },
  format: {
    predicate: it => !!it && PHONE_REGEX.test(unmask(it)),
    errorMessage: 'validation.common.phone.format',
  },
};

export const validatePhoneNumber = (value: string) => {
  return ValidationUtils.validate(value, PHONE_NUMBER_VALIDATION);
};

const CNPJ_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.generic.required',
  },
  format: {
    predicate: it => !!it && isCNPJ(unmask(it)),
    errorMessage: 'validation.common.cnpj.format',
  },
  length: {
    predicate: it => !!it?.match?.length && it.match.length !== 14,
    errorMessage: 'validation.common.cnpj.length',
  },
};

export const validateCNPJ = (value: string) => {
  return ValidationUtils.validate(value, CNPJ_VALIDATION);
};

const CPF_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.generic.required',
  },
  format: {
    predicate: it => !!it && isCPF(unmask(it)),
    errorMessage: 'validation.common.cpf.format',
  },
};

export const validateCPF = (value: string) => {
  return ValidationUtils.validate(value, CPF_VALIDATION);
};

const ZIPCODE_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it && isCEP(unmask(it)),
    errorMessage: 'validation.common.generic.required',
  },
};

export const validateZipCode = (value: string) => ValidationUtils.validate(value, ZIPCODE_VALIDATION);

const OBJECT_VALIDATION: Validations<any> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.generic.required',
  },
};

export const validateObject = (value: any) => {
  return ValidationUtils.validate(value, OBJECT_VALIDATION);
};

const OBJECT_LIST_VALIDATION: Validations<any[]> = {
  required: {
    predicate: it => !!it && it.length > 0,
    errorMessage: 'validation.common.generic.required',
  },
};

export const validateObjectList = (value: any[]) => {
  return ValidationUtils.validate(value, OBJECT_LIST_VALIDATION);
};

const GENERIC_FIELD_VALIDATION: Validations<string> = {
  required: {
    predicate: it => !!it,
    errorMessage: 'validation.common.generic.required',
  },
};

const RESIDUES_VALIDATION: Validations<any> = {
  required: {
    predicate: it => !!it && it.length <= 1,
    errorMessage: 'validation.mtrResidues.transshipment',
  },
};

export const validateResidueListMtr = (value: any) => {
  return ValidationUtils.validate(value, RESIDUES_VALIDATION);
};

export const validateGenericField = (value: string) => ValidationUtils.validate(value, GENERIC_FIELD_VALIDATION);

const GENERIC_FIELD_LIST_VALIDATION: Validations<any[]> = {
  required: {
    predicate: it => !!it && it.length > 0,
    errorMessage: 'validation.common.generic.required',
  },
};

export const validateGenericFieldList = (value: any[]) => ValidationUtils.validate(value, GENERIC_FIELD_LIST_VALIDATION);
