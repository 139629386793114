import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-component.scss';

// registerLocale('ptBr', ptBr);

class ptBrUtils extends DateFnsUtils {
  getWeekdays() {
    return ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  }
}

export interface IDatePickerComponentProps {
  dateFormat: string;
  dateSelected?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  onDateChange?: (date: string) => void;
  minDate?: string;
  maxDate?: string;
}

export interface IDatePickerComponentState {
  open?: boolean;
}

export default class DatePickerComponent extends React.Component<IDatePickerComponentProps, IDatePickerComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onDateChange = value => {
    this.props.onDateChange(moment(value).format('YYYY-MM-DD'));
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { dateFormat, dateSelected, placeholder, error, errorMessage, minDate, maxDate } = this.props;
    const { open } = this.state;

    const parsedMinDate = minDate ? new Date(new Date(minDate).setHours(0, 0, 0, 0)) : undefined;
    const parsedMaxDate = maxDate ? new Date(new Date(maxDate).setHours(23, 59, 59, 999)) : undefined;
    console.log('Min Date:', parsedMinDate);
    console.log('Max Date:', parsedMaxDate);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: "1 1 35%" }}>
        <div className="datepicker__items">
          <MuiPickersUtilsProvider utils={ptBrUtils} locale={ptBr}>
            <DatePicker
              autoOk
              label={placeholder}
              value={dateSelected ? moment(dateSelected).toDate() : null}
              format="dd/MM/yyyy"
              variant="inline"
              inputVariant="filled"
              className="form-control input-field datepicker__items--input"
              onChange={this.onDateChange}
              open={open}
              onClose={this.toggleOpen}
              onOpen={this.toggleOpen}
              minDate={parsedMinDate} // Aplica a data mínima convertida
              maxDate={parsedMaxDate} // Aplica a data máxima convertida
              onKeyUp={e => {
                this.toggleOpen();
                e.preventDefault();
              }}

            />
          </MuiPickersUtilsProvider>
          <div className="datepicker__items--img" />
        </div>
        {error && <span className="datepicker__error">{errorMessage}</span>}
      </div>
    );
  }
}
