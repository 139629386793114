import AlertModal from 'app/components/alert-modal/alert-modal';
import CustomTextField from 'app/components/custom-text-field/custom-text-field';
import DatePickerComponent from 'app/components/date-picker-component/date-picker-component';
import DropdownSearch from 'app/components/dropdown-search/dropdown-search';
import { ALL_USER_AUTHORITIES, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { searchFinalDestinations } from 'app/entities/final-destination/final-destination.reducer';
import { validateGenericField, validateObject, validateResidueListMtr } from 'app/modules/validation/validation-constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { IDriver, mapDriverToSearch } from 'app/shared/model/driver.model';
import { HttpRequestStatus } from 'app/shared/model/enum/HttpRequestStatus';
import { IFinalDestination, mapFinalDestinationToSearch } from 'app/shared/model/final-destination.model';
import { IMtrResidue } from 'app/shared/model/mtr-residue.model';
import { IMtr, IntegrationMtrStatus, MtrStatus } from 'app/shared/model/mtr.model';
import { IProducerMtr, mapProducersToSearch } from 'app/shared/model/producer.model';
import { ISourceResidueMtr, mapSourceResiduesToSearch } from 'app/shared/model/source-residue.model';
import { ITransporterMtr, mapTransportToSearch } from 'app/shared/model/transporter.model';
import { IVehicle, mapVehicleToSearch } from 'app/shared/model/vehicle.model';
import { IRootState } from 'app/shared/reducers';
import { InvalidReulst, translateErrorMessage, ValidationResult, ValidationResultInstance } from 'app/shared/util/validation-utils';
import React, { useEffect, useState } from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import { reset as resetDrivers, searchDrivers } from '../../../../entities/driver/driver.reducer';
import { reset as resetProducers, searchProducers } from '../../../../entities/producer/producer.reducer';
import { reset as resetResidues, searchSourceResidues } from '../../../../entities/source-residue/source-residue.reducer';
import { reset as resetVehicles, searchVehicles } from '../../../../entities/vehicle/vehicle.reducer';
import { reset as resetTransporters, searchTransporters } from '../../../../modules/transporter/transport/transport-reducer';
import {
    cancelMtr,
    findMtr,
    getMtrFinalDestination,
    getMtrProducer,
    getMtrResidue,
    getMtrTransporter,
    getMtrTransshipment,
    reset as resetMtr,
    resetStatus,
    saveMtr,
    savePdf,
    getMtrs,
} from '../mtr-reducer';
import { getMtrModels } from '../../../producer/mtr-model/mtr-model.reducer'
import './mtr-manage.scss';
import { renderFinalDestination, renderMtrResidue, renderProducer, renderTransporter, renderTransshipment, renderUnits, renderUnitsFinalDestiny, renderUnitsTransporter, renderUnitsTransshipment } from './mtr-templates';
import { getMtrStatusStyle, mtrView } from './mtr-view/mtr-view';
import { MeasurementType } from 'app/shared/model/authorization.model';
import { mapTransshipmentToSearch, Transshipment } from 'app/shared/model/transshipment.model';
import { searchTransshipments } from 'app/entities/transshipment/transshipment.reducer';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { mapUnitToSearchUserIntegration, ProducerUnit, UnitProducerOrFinalDestination, TransporterUnit, TransshipmentUnit } from 'app/shared/model/unit';

import {
    getIntegrationUnits,
    getIntegrationUnitsProducer,
    getIntegrationUnitsFinalDestiny,
    getIntegrationUnitsTransporter,
    getIntegrationUnitsTransshipment,
} from '../../../../modules/producer/units/producer-units-reducer';
import { getIntegrationUsers } from '../../user-integration/user-integration.reducer';
import { AlertModalPlanRestricted } from 'app/components/alertModalPlanRestricted/alert-modal';
import { Pageable } from 'app/shared/model/pageable';
import { IIntegrationUser } from 'app/shared/model/integration-user.model';
import CustomTextFieldObservation from 'app/components/custom-text-field-observation/custom-text-field-observation';
import { toast } from 'react-toastify';
import axios from 'axios';
import { IIntegrationQueue } from 'app/shared/model/integration-queue.model';
import { Alert } from '@material-ui/lab';

export interface IMtrCreateProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
    page: Pageable;
    mtr: IMtr;

}

const MtrManage = (props: IMtrCreateProps): JSX.Element => {
    const [state, setState] = useState({
        page: { size: 500, page: 0, totalElements: 0, totalPages: 0 },
        userIntegration: null,
    });

    const [finalDestination, setFinalDestination] = useState<IFinalDestination | undefined>();
    const [transshipment, setTransshipment] = useState<Transshipment | undefined>();
    const [transporter, setTransporter] = useState<ITransporterMtr | undefined>(undefined);
    const [unit, setUnit] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [unitFinalDestiny, setUnitFinalDestiny] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [unitTransporter, setUnitTransporter] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [unitTransshipment, setUnitTransshipment] = useState<UnitProducerOrFinalDestination | undefined>(undefined);
    const [unitIDProducerModel, setUnitIDProducerModel] = useState<ProducerUnit | undefined>(undefined);
    const [unitIDFinalDestinationModel, setUnitIDFinalDestinationModel] = useState<ProducerUnit | undefined>(undefined);
    const [unitIDTransporterModel, setUnitIDTransporterModel] = useState<ProducerUnit | undefined>(undefined);
    const [unitIDTransshipmentModel, setUnitIDTransshipmentModel] = useState<ProducerUnit | undefined>(undefined);
    const [collectionDate, setCollectionDate] = useState<string | undefined>(undefined);
    const [integrationEnabled, setIntegrationEnabled] = useState<boolean | undefined>(true);
    const [residueIndex, setResidueIndex] = useState<number | undefined>(undefined);
    const [producer, setProducer] = useState<IProducerMtr | undefined>(undefined);
    const [producerUnit, setProducerUnit] = useState<ProducerUnit | undefined>(undefined);
    const [transporterUnit, setTransporterUnit] = useState<TransporterUnit | undefined>(undefined);
    const [transshipmentUnit, setTransshipmentUnit] = useState<ProducerUnit | undefined>(undefined);
    const [finalDestinationUnit, setFinalDestinationUnit] = useState<ProducerUnit | undefined>(undefined);
    const [vehicle, setVehicle] = useState<IVehicle | undefined>(undefined);
    const [driver, setDriver] = useState<IDriver | undefined>(undefined);
    const [residues, setResidues] = useState<IMtrResidue[]>([]);
    const [mtr, setMtr] = useState<IMtr>();
    const [editFlag, setEditFlag] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showModalPlan, setShowModalPlan] = useState<boolean>(false);

    const [showCreateSuccessModal, setCreateSuccessModal] = useState<boolean>(false);
    const [showCancelSuccessModal, setCancelSuccessModal] = useState<boolean>(false);
    const [showCancelModal, setCancelModal] = useState<boolean>(false);
    const [mtrCancelId, setMtrCancelId] = useState<number>(null);

    const [finalDestinationError, setFinalDestinationError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [transshipmentError, setTransshipmentError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [producerError, setProducerError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [transporterError, setTransporterError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [UnitError, setUnitError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [driverError, setDriverError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [vehicleError, setVehicleError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [residueError, setResidueError] = useState<Map<number, ValidationResult> | undefined>(new Map<number, ValidationResult>());
    const [amountError, setAmountError] = useState<Map<number, ValidationResult> | undefined>(new Map<number, ValidationResult>());
    const [collectionDateError, setCollectionDateError] = useState<ValidationResult | undefined>(ValidationResultInstance);
    const [viewModeOnly, setViewModeOnly] = useState<boolean>(false);
    const history = useHistory();
    const [integrationItem, setIntegrationItem] = useState<IIntegrationQueue>();

    const useForceUpdate = () => useState()[1];

    const [observation, setObservation] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const mtrId = props.location?.state != null ? props.location?.state['mtrId'] : null;
        setIsOpen(props.location.state != null ? !props.location.state['collapsed'] : false);

        if (mtrId == null) {
            if (props.hasTransporterAuthorities) {
                props.getMtrTransporter();
            } else if (props.hasProducerAuthorities) {
                props.getMtrProducer();
            }

            setResidues([{}]);
            // Initial get
            getProducers('');
            getTransporters('');
            getTransshipments('');
            getSourceResidues('');
            getFinalDestinations('');
            props.getMtrModels();
        } else {
            props.findMtr(mtrId);
        }
    }, []);

    useEffect(() => {
        if (props.getMtrStatus === HttpRequestStatus.SUCCESS && props.mtr != null) {
            const mtr = props.mtr;
            setResidues(mtr.mtrResidues != null && mtr.mtrResidues.length > 0 ? mtr.mtrResidues : [{}]);
            setFinalDestination(mtr.finalDestination);
            setCollectionDate(mtr.collectionDate);
            setIntegrationEnabled(mtr.integrationEnabled);
            setTransshipment(mtr.transshipment);
            setTransporter(mtr.transporter);
            setProducer(mtr.producer);
            setVehicle(mtr.vehicle);
            setDriver(mtr.driver);
            setMtr(mtr);
            setViewModeOnly(mtr.viewModeOnly);
        }
    }, [props.mtr, props.getMtrStatus])

    useEffect(() => {
        if (transporter != null && transporter.id != null) {
            getDrivers('');
            getVehicle('');
        }
        if (transporter == null) {
            setDriver(null);
            setVehicle(null);
            props.resetDrivers();
            props.resetVehicles();
        }
    }, [transporter]);

    useEffect(() => {
        if (editFlag != null && editFlag) {
            setResidues(mtr.mtrResidues != null && mtr.mtrResidues.length > 0 ? mtr.mtrResidues : [{}]);
            setFinalDestination(mtr.finalDestination);
            setCollectionDate(mtr.collectionDate);
            setIntegrationEnabled(mtr.integrationEnabled);
            setTransshipment(mtr.transshipment);
            setTransporter(mtr.transporter);
            setProducer(mtr.producer);
            setVehicle(mtr.vehicle);
            setDriver(mtr.driver);
            setMtr(mtr);
            setUnit(mapUnit(mtr?.producerUnit));
            setUnitFinalDestiny(mapUnit(mtr?.finalDestinationUnit));
            setUnitTransporter(mapUnit(mtr?.transporterUnit));
            setUnitTransshipment(mapUnit(mtr?.transshipmentUnit));
            getProducers('');
            getTransporters('');
            getTransshipments('');
            getSourceResidues('');
            getFinalDestinations('');
        }
    }, [editFlag]);

    useEffect(
        () => () => {
            props.resetMtr();
            props.resetDrivers();
            props.resetProducers();
            props.resetResidues();
            props.resetTransporters();
            props.resetVehicles();
            props.resetStatus();
        },
        []
    );

    useEffect(() => {
        if (props.residue != null && props.residue != null) {
            updateSourceResidue(props.residue, residueIndex);
        }
    }, [props.residue]);

    useEffect(() => {
        if (props.saveMtrStatus == HttpRequestStatus.SUCCESS) {
            mtr == null && setCreateSuccessModal(true);
            props.resetMtr();
            props.findMtr(props.savedMtr.id);
        }
    }, [props.saveMtrStatus, props.savedMtr]);

    useEffect(() => {
        if (props.cancelMtrStatus == HttpRequestStatus.SUCCESS) {
            setCancelSuccessModal(true);
        }
    }, [props.cancelMtrStatus]);

    useEffect(() => {
        if (props.producer != producer && props.producer != null) {
            setProducer(props.producer);
        }
    }, [props.producer]);

    useEffect(() => {
        if (transporter != props.transporter && props.transporter != null) {
            setTransporter(props.transporter);
        }
    }, [props.transporter]);

    useEffect(() => {
        if (finalDestination != props.finalDestination && props.finalDestination != null) {
            setFinalDestination(props.finalDestination);
        }
    }, [props.finalDestination]);

    useEffect(() => {
        if (transshipment != props.transshipment && props.transshipment != null) {
            setTransshipment(props.transshipment);
        }
    }, [props.transshipment]);

    useEffect(() => {
        const { id } = (props.location.state as any) || { id: null };
        if (props.hasProducerAuthorities || props.hasManagerAuthorities || props.hasTransporterAuthorities || props.hasFinalDestinationAuthorities || props.hasTransshipmentAuthorities) {
            props.getIntegrationUsers(id);
        }
    }, [props.hasProducerAuthorities, props.hasManagerAuthorities, props.hasTransporterAuthorities, props.hasFinalDestinationAuthorities, props.hasTransshipmentAuthorities]);

    const mapUnit = (unit) => {
        let unitAddressLabelValue = '';

        if (unit?.address?.street !== undefined) {
            unitAddressLabelValue += unit.address.street;

            if (unit.address.complement !== undefined) {
                unitAddressLabelValue += ', ' + unit.address.complement;
            }

            if (unit.address.city?.name !== undefined && unit.address.city.state?.name !== undefined) {
                unitAddressLabelValue += '. ' + unit.address.city.name + '/' + unit.address.city.state.name;
            }
        }

        let objUnit = {
            name: unit?.fantasyName,
            unitCode: unit?.unitCode,
            unitAddressLabel: unitAddressLabelValue,
            unitZipCode: unit?.address.zipcode
        };

        return objUnit;
    }

    const getProducers = (text: string) => {
        props.searchProducers(0, 1000, undefined, { fantasyName: text });
    };

    const getSourceResidues = (text: string) => {
        const { page } = state;
        props.searchSourceResidues(page.page, page.size, undefined, { 'specificResidue.name': text });
    };

    const getFinalDestinations = (text: string) => {
        props.searchFinalDestinations(0, 1000, undefined, { fantasyName: text });
    };

    const getTransshipments = (text: string) => {
        props.searchTransshipments(0, 1000, undefined, { fantasyName: text });
    };

    const getUnitsProducer = (text: string) => {
        if (producer) {
            props.getIntegrationUnitsProducer(producer.id.toString());
        }
    };

    const getUnitsTransporter = (text: string) => {
        if (transporter) {
            props.getIntegrationUnitsTransporter(transporter.id.toString());
        }
    };

    const getUnitsTransshipment = (text: string) => {
        if (transshipment) {
            props.getIntegrationUnitsTransshipment(transshipment.id.toString());
        }
    }

    const getUnitsFinalDestination = (text: string) => {
        if (finalDestination) {
            props.getIntegrationUnitsFinalDestiny(finalDestination.id.toString());
        }
    };

    const getTransporters = (text: string) => {
        props.searchTransporters(0, 1000, undefined, { searchText: text });
    };

    const getDrivers = (text: string) => {
        props.searchDrivers(0, 1000, undefined, { searchText: text, transporterId: transporter.id });
    };

    const getVehicle = (text: string) => {
        props.searchVehicles(0, 1000, undefined, { plate: text, transporterId: transporter.id });
    };

    const validateFinalDestination = () => {
        const validate = validateObject(finalDestination);
        setFinalDestinationError(validate);
        return validate.hasError;
    };

    const validateTransshipment = () => {
        const validate = validateObject(transshipment);
        setTransshipmentError(validate);
        return validate.hasError;
    };

    const validateTransshipmentResidues = () => {
        const validate = validateResidueListMtr(residues);
        setTransshipmentError(validate);
        return validate.hasError;
    };

    const setMtrTransshipment = item => {
        if (!validateTransshipmentResidues()) {
            props.getMtrTransshipment(item['id']);
        }
    };

    const validateProducer = () => {
        const validate = validateObject(producer);
        setProducerError(validate);
        return validate.hasError;
    };

    const validateUnit = () => {
        const validate = validateObject(unit);
        setUnitError(validate);
        return validate.hasError;
    };

    const validateTransporter = () => {
        const validate = validateObject(transporter);
        setTransporterError(validate);
        return validate.hasError;
    };

    const validateDriver = () => {
        const validate = validateObject(driver);
        setDriverError(validate);
        return validate.hasError;
    };

    const validateVehicle = () => {
        const validate = validateObject(vehicle);
        setVehicleError(validate);
        return validate.hasError;
    };

    const validateResidue = () => {
        let hasError = false;
        residueError.clear();
        if (residues.length == 0) {
            return true;
        }

        residues.forEach((item, index) => {
            const validate = validateObject(item.sourceResidue);
            residueError.set(index, validate);
            if (validate.hasError) {
                hasError = true;
            }
        });

        return hasError;
    };

    const validateAmount = () => {
        let hasError = false;
        amountError.clear();
        if (residues.length == 0) {
            return true;
        }

        residues.forEach((item, index) => {
            const validate = validateGenericField(item.amount ? item.amount.toString() : null);
            amountError.set(index, validate);
            if (validate.hasError) {
                hasError = true;
            }
        });

        return hasError;
    };

    const validateAmountReceipt = () => {
        let hasError = false;
        amountError.clear();
        if (residues.length == 0) {
            return true;
        }

        residues.forEach((item, index) => {
            const validate = validateGenericField(item.amountReceipt ? item.amountReceipt.toString() : null);
            amountError.set(index, validate);
            if (validate.hasError) {
                hasError = true;
            }
        });

        return hasError;
    };

    const validateCollectionDate = () => {
        const validate = validateGenericField(collectionDate);
        setCollectionDateError(validate);
        return validate.hasError;
    };
    const validateAmountWeightToSave = () => {
        let hasError = false;
        let maxWeightKgL = 45000;
        let maxWeightTC = 45;

        for (let i = 0; residues.length > i; i++) {
            const item = residues[i];
            if (item.amount <= 0) {
                amountError.clear()
                amountError.set(i, {
                    hasError: true,
                    errorMessage: 'mtr.errors.lower'
                } as InvalidReulst)
                return true;
            }
            switch (item.measurementType) {
                case MeasurementType.KG:
                case MeasurementType.LITERS:
                    if (item.amount > maxWeightKgL) {
                        amountError.clear()
                        amountError.set(i, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;
                case MeasurementType.TONS:
                case MeasurementType.CUBIC_METERS:
                    if (item.amount > maxWeightTC) {
                        amountError.clear()
                        amountError.set(i, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;

            }
            if (hasError) {
                amountError.clear()
                amountError.set(i, {
                    hasError: true,
                    errorMessage: 'mtr.errors.amount'
                } as InvalidReulst)
                return true;
            }

            if (hasError === false) {
                amountError.clear();
            }
        }

        return hasError;
    };

    const validateAmountWeight = () => {
        let hasError = false;
        let maxWeightKgL = 45000;
        let maxWeightTC = 45;

        residues.forEach((item, index) => {
            if (item.amount <= 0) {
                amountError.clear()
                hasError = true;
                amountError.set(index, {
                    hasError: true,
                    errorMessage: 'mtr.errors.lower'
                } as InvalidReulst)
                return true;
            }
            switch (item.measurementType) {
                case MeasurementType.TONS:
                case MeasurementType.CUBIC_METERS:
                    if (item.amount > maxWeightTC) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;
                case MeasurementType.KG:
                case MeasurementType.LITERS:
                default:
                    if (item.amount > maxWeightKgL) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;

            }

            if (hasError === false) {
                amountError.clear();
            }
        });

        return hasError;
    };
    const validateAmountReceiptWeight = () => {
        let hasError = false;
        let maxWeightKgL = 45000;
        let maxWeightTC = 45;

        residues.forEach((item, index) => {
            if (item.amountReceipt <= 0) {
                amountError.clear()
                hasError = true;
                amountError.set(index, {
                    hasError: true,
                    errorMessage: 'mtr.errors.lower'
                } as InvalidReulst)
                return true;
            }
            switch (item.measurementType) {
                case MeasurementType.TONS:
                case MeasurementType.CUBIC_METERS:
                    if (item.amount > maxWeightTC) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;
                case MeasurementType.KG:
                case MeasurementType.LITERS:
                default:
                    if (item.amountReceipt > maxWeightKgL) {
                        hasError = true;
                        amountError.clear()
                        amountError.set(index, {
                            hasError: true,
                            errorMessage: 'mtr.errors.greater'
                        } as InvalidReulst)
                        return true;
                    }
                    break;

            }

            if (hasError === false) {
                amountError.clear();
            }
        });

        return hasError;
    };

    const validateAll = () => {
        let hasError = false;
        if (validateCollectionDate()) {
            hasError = true;
        }
        if (props.hasManagerAuthorities && validateProducer()) {
            hasError = true;
        }
        if (validateAmountWeightToSave()) {
            hasError = true;
        }

        return hasError;
    };

    const saveMtr = () => {

        if (props.saveMtrStatus === HttpRequestStatus.ONGOING || validateAll()) {
            return;
        }

        residues.forEach(it => {
            if (!it.measurementType) {
                it.measurementType = MeasurementType.KG;
            }
        });
        setEditFlag(false);

        const objMtr = {
            id: mtr != null ? mtr.id : undefined,
            producer,
            producerUnit,
            transporter,
            transporterUnit,
            integrationEnabled,
            finalDestination,
            finalDestinationUnit,
            transshipment,
            transshipmentUnit,
            driver,
            vehicle,
            collectionDate,
            observation,
            mtrResidues: residues.filter(it => it.sourceResidue != null),
        }
        props.saveMtr(objMtr);
    };

    const clearUnits = () => {
        setUnit(undefined);
    };

    const clearUnitsFinalDestiny = () => {
        setUnitFinalDestiny(undefined);
    };

    const clearUnitsTransporter = () => {
        setUnitTransporter(undefined);
    };

    const clearUnitsTransshipment = () => {
        setUnitTransshipment(undefined);
    }

    const clearTransporter = () => {
        setTransporter(undefined);
    };

    const clearTransshipment = () => {
        setTransshipment(undefined);
    };

    const setUnitWithTransporter = (item) => {
        setTransporterUnit({ id: item.id })
        setUnitTransporter(item)
    }

    const setUnitWithProducer = (item) => {
        setProducerUnit({ id: item.id })
        setUnit(item)
    }

    const setUnitWithTransshipment = (item) => {
        setTransshipmentUnit({ id: item.id })
        setUnitTransshipment(item)
    }

    const setUnitWithFinalDestiny = (item) => {
        setFinalDestinationUnit({ id: item.id })
        setUnitFinalDestiny(item)
    }

    const closeModal = () => {
        setShowModalPlan(false)
    }

    const openModal = () => {
        setShowModalPlan(true)
    }

    useEffect(() => {
        if (props.saveMtrErrorMessage) {
            openModal()
        }
    }, [props.saveMtrErrorMessage])


    const renderUnitContent = () => {
        if (unit) {
            return renderUnits(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnits,
                unit
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {producer !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearchUserIntegration(props.unitsProducer)}
                                getData={value => getUnitsProducer(value)}
                                onClickItem={item => setUnitWithProducer(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const renderUnitFinalDestinyContent = () => {
        if (unitFinalDestiny) {
            return renderUnitsFinalDestiny(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnitsFinalDestiny,
                unitFinalDestiny
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {finalDestination !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearchUserIntegration(props.unitsFinalDestination)}
                                getData={value => getUnitsFinalDestination(value)}
                                onClickItem={item => setUnitWithFinalDestiny(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const renderTransporterContent = () => {
        if (transporter != null) {
            return renderTransporter(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearTransporter,
                transporter,
                mtr
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
                        <DropdownSearch
                            data={mapTransportToSearch(props.transporters)}
                            getData={value => getTransporters(value)}
                            onClickItem={item => props.getMtrTransporter(item['id'])}
                            title={'Selecione o transportador'}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                            error={transporterError.hasError}
                            errorMessage={translateErrorMessage(transporterError.errorMessage)}
                        />
                    </Col>
                </Row>
            );
        }
    };

    const renderUnitTransporterContent = () => {
        if (unitTransporter) {
            return renderUnitsTransporter(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnitsTransporter,
                unitTransporter
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {transporter !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearchUserIntegration(props.unitsTransporter)}
                                getData={value => getUnitsTransporter(value)}
                                onClickItem={item => setUnitWithTransporter(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const renderUnitTransshipmentContent = () => {
        if (unitTransshipment) {
            return renderUnitsTransshipment(
                !props.hasTransporterAuthorities && !props.hasFinalDestinationAuthorities,
                clearUnitsTransshipment,
                unitTransshipment
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                        {transshipment !== undefined ?
                            <DropdownSearch
                                data={mapUnitToSearchUserIntegration(props.unitsTransshipment)}
                                getData={value => getUnitsTransshipment(value)}
                                onClickItem={item => setUnitWithTransshipment(item)}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                            :
                            <DropdownSearch
                                data={[]}
                                getData={value => { }}
                                onClickItem={item => { }}
                                title={'Selecione a Unidade'}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                showSearchField
                                filterLocalData
                                error={UnitError.hasError}
                                errorMessage={translateErrorMessage(UnitError.errorMessage)}
                            />
                        }
                    </Col>
                </Row>
            );
        }
    };

    const setCurrtentModel = (item) => {
        if (item.transporter?.id) {
            props.getMtrTransporter(item.transporter?.id)
            props.getIntegrationUnitsTransporter(item.transporter?.id)
        }
        if (item.producer?.id) {
            props.getMtrProducer(item.producer?.id)
            props.getIntegrationUnitsProducer(item.producer?.id)
        }
        if (item.transshipment?.id) {
            props.getMtrTransshipment(item.transshipment?.id)
            props.getIntegrationUnitsTransshipment(item.transshipment?.id)
        }
        if (item.finalDestination?.id) {
            props.getMtrFinalDestination(item.finalDestination?.id)
            props.getIntegrationUnitsFinalDestiny(item.finalDestination?.id)
        }

        setUnitIDProducerModel(item.producerUnit?.id ?? undefined)
        setUnitIDFinalDestinationModel(item.finalDestinationUnit?.id ?? undefined)
        setUnitIDTransporterModel(item.transporterUnit?.id ?? undefined)
        setUnitIDTransshipmentModel(item.transshipmentUnit?.id ?? undefined)

        if (item.mtrResidues) {
            delete item.mtrResidues[0]['id']
            setResidues(item.mtrResidues)
        }
    }

    useEffect(() => {
        if (unitIDProducerModel) {
            props.unitsProducer?.forEach((element) => {
                if (element.id === unitIDProducerModel) {
                    setUnit(mapUnit(element))
                    setProducerUnit({ id: element.id })
                }
            })
        } else {
            setUnit(undefined)
        }
    }, [props.unitsProducer])

    useEffect(() => {
        if (!unitIDProducerModel) {
            setUnit(undefined)
        }
    }, [unitIDProducerModel])

    useEffect(() => {
        if (unitIDTransporterModel) {
            props.unitsTransporter?.forEach((element) => {
                if (element.id === unitIDTransporterModel) {
                    setUnitTransporter(mapUnit(element))
                    setTransporterUnit({ id: element.id })
                }
            })
        } else {
            setUnitTransporter(undefined)
        }
    }, [props.unitsTransporter])

    useEffect(() => {
        if (!unitIDTransporterModel) {
            setUnitTransporter(undefined)
        }
    }, [unitIDTransporterModel])

    useEffect(() => {
        if (unitIDTransshipmentModel) {
            props.unitsTransshipment?.forEach((element) => {
                if (element.id === unitIDTransshipmentModel) {
                    setUnitTransshipment(mapUnit(element))
                    setTransshipmentUnit({ id: element.id })
                }
            })
        }
    }, [props.unitsTransshipment])

    useEffect(() => {
        if (!unitIDTransshipmentModel) {
            setUnitTransshipment(undefined)
        }
    }, [unitIDTransshipmentModel])

    useEffect(() => {
        if (unitIDFinalDestinationModel) {
            props.unitsFinalDestination?.forEach((element) => {
                if (element.id === unitIDFinalDestinationModel) {
                    setUnitFinalDestiny(mapUnit(element))
                    setFinalDestinationUnit({ id: element.id })
                }
            })
        }
    }, [props.unitsFinalDestination])

    useEffect(() => {
        if (!unitIDFinalDestinationModel) {
            setUnitFinalDestiny(undefined)
        }
    }, [unitIDFinalDestinationModel])


    const renderMtrModelContent = () => {

        return (
            <Row style={{ width: '50%' }}>
                <Col md={{ size: 10, offset: 1 }}>
                    <DropdownSearch
                        data={props.mtrModels.reverse()}
                        getData={value => console.log(value)}
                        onClickItem={item => setCurrtentModel(item)}
                        title={'Buscar o modelo'}
                        style={{ backgroundColor: '#f6f6f6' }}
                        notAlphabeticalOrder
                        showInsiderSelect
                        filterLocalData
                        showSearchField
                    />
                </Col>
            </Row>
        );

    };

    const updateSourceResidue = (sourceResidue: ISourceResidueMtr, index: number) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].sourceResidue = sourceResidue;
            setResidueIndex(undefined);
        }
        setResidues(newArr);
        resetStatus();
    };

    const updateAmount = (index: number, amount: string) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].amount = Number(amount.split('.').join('').replace(/,/, '.'));
            setResidues(newArr);
            validateAmountWeight()
        }
    };

    const updateAmountReceipt = (index: number, amountReceipt: string) => {
        let newArr2 = [...residues];
        if (index != null) {
            newArr2[index].amountReceipt = Number(amountReceipt.split('.').join('').replace(/,/, '.'));
            setResidues(newArr2);
            validateAmountReceiptWeight()
        }
    };

    const updateJustification = (index: number, justification: string) => {
        let newArr2 = [...residues];
        if (index != null) {
            newArr2[index].justification = justification;
            setResidues(newArr2);
        }
    };

    const updateMeasurementType = (index: number, measurementType: any) => {
        let newArr = [...residues];
        if (index != null) {
            newArr[index].measurementType = measurementType.keyEnum;
            setResidues(newArr);
        }
    };

    const mapSelectedMeasurementTypeToSelect = (index: number) => {
        let newArr = [...residues];
        let measurementType: MeasurementType;
        if (index != null) {
            if (newArr[index].measurementType != null) {
                measurementType = newArr[index].measurementType;
            } else {
                measurementType = newArr[index].sourceResidue?.measurementType;
            }
        }
        return measurementType
            ? {
                name: translate(`enums.measurementTypeAbbreviation.${measurementType}`),
                keyEnum: measurementType,
            }
            : {
                name: translate(`enums.measurementTypeAbbreviation.KG`),
                keyEnum: translate(`enums.measurementTypeAbbreviation.KG`),
            };
    };

    const removeSourceResidue = (index: number) => {
        let newArr = [...residues];
        if (residueError.get(index) != null) {
            residueError.delete(index);
        }
        if (amountError.get(index) != null) {
            amountError.delete(index);
        }
        if (newArr.length > 1) {
            newArr.splice(index, 1);
        } else {
            newArr[index] = {};
        }
        setResidues(newArr);
    };

    const addSourceResidue = () => {
        let newArr = [...residues];
        newArr.push({});
        setResidues(newArr);
    };

    useEffect(() => {
        if (producer) {
            props.getIntegrationUnitsProducer(producer.id.toString())
        }
    }, [producer])


    const renderProducerContent = () => {
        if (producer != null) {
            return renderProducer(!props.hasProducerAuthorities && !props.hasFinalDestinationAuthorities, () => setProducer(undefined), producer);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
                        <DropdownSearch
                            data={mapProducersToSearch(props)}
                            getData={value => getProducers(value)}
                            onClickItem={item => props.getMtrProducer(item['id'])}
                            title={translate('mtr-manage.producer')}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                            error={producerError.hasError}
                            errorMessage={translateErrorMessage(producerError.errorMessage)}
                        />
                    </Col>
                </Row>
            );
        }
    };

    useEffect(() => {
        if (finalDestination) {
            props.getIntegrationUnitsFinalDestiny(finalDestination.id.toString())
        }
    }, [finalDestination])

    useEffect(() => {
        if (transporter) {
            props.getIntegrationUnitsTransporter(transporter.id.toString())
        }
    }, [transporter])

    useEffect(() => {
        if (transshipment) {
            props.getIntegrationUnitsTransshipment(transshipment.id.toString())
        }
    }, [transshipment])


    const renderFinalDestinationContent = () => {
        if (finalDestination != null) {
            return renderFinalDestination(!props.hasFinalDestinationAuthorities, () => setFinalDestination(undefined), finalDestination, mtr);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
                        <DropdownSearch
                            data={mapFinalDestinationToSearch(props)}
                            getData={value => getFinalDestinations(value)}
                            onClickItem={item => props.getMtrFinalDestination(item['id'])}
                            title={translate('mtr-manage.finalDestination')}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                            disableOpen={props.hasFinalDestinationAuthorities}
                            error={finalDestinationError.hasError}
                            errorMessage={translateErrorMessage(finalDestinationError.errorMessage)}
                        />
                    </Col>
                </Row>
            );
        }
    };

    const renderTransshipmentContent = () => {
        if (transshipment != null) {
            return renderTransshipment(!props.hasFinalDestinationAuthorities, () => setTransshipment(undefined), transshipment, mtr);
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.transshipment')}</div>
                        <DropdownSearch
                            data={mapTransshipmentToSearch(props)}
                            getData={value => getTransshipments(value)}
                            onClickItem={item => setMtrTransshipment(item)}
                            title={'Selecione o transbordo'}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            showSearchField
                            filterLocalData
                            disableOpen={props.hasTransshipmentAuthorities || props.hasFinalDestinationAuthorities}
                            error={transshipmentError.hasError}
                            errorMessage={translateErrorMessage(transshipmentError.errorMessage)}
                        />
                    </Col>
                </Row>
            );
        }
    };

    const mapSelectedDriverToSelect = () => {
        return driver ? { name: driver.userTransporter.user.name.toUpperCase(), id: driver.id } : null;
    };

    const renderDriverContent = () => {
        if (driver != null && !editFlag) {
            return (
                <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '2px' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.driver')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">{translate('mtr.driver')}</div>
                            <div className="list__items--text">{driver.userTransporter.user.name.toUpperCase()}</div>
                        </div>
                    </div>
                </Col>
            );
        } else {
            return (
                <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '2px' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.driver')}</div>
                    <DropdownSearch
                        data={mapDriverToSearch(props.drivers as IDriver[])}
                        getData={value => getDrivers(value)}
                        selectedData={mapSelectedDriverToSelect()}
                        onClickItem={item => setDriver(props.drivers.find(it => it.id === item['id']))}
                        title={translate('mtr-manage.driver')}
                        style={{ backgroundColor: '#f6f6f6' }}
                        notAlphabeticalOrder
                        showInsiderSelect
                        showSearchField
                        filterLocalData
                        error={driverError.hasError}
                        errorMessage={translateErrorMessage(driverError.errorMessage)}
                    />
                </Col>
            );
        }
    };

    const mapSelectedVehicleToSelect = () => {
        return vehicle ? { name: vehicle.plate, id: vehicle.id } : null;
    };

    const renderVehicleContent = () => {
        if (vehicle != null && !editFlag) {
            return (
                <Col md={{ size: 5, offset: 0 }} style={{ paddingLeft: '2px' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.vehicle')}</div>
                    <div className="list__items--component">
                        <div>
                            <div className="list__items--title">Veículo</div>
                            <div className="list__items--text">{vehicle.plate}</div>
                        </div>
                    </div>
                </Col>
            );
        } else {
            return (
                <Col md={{ size: 5 }} style={{ paddingLeft: '2px' }}>
                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.vehicle')}</div>
                    <DropdownSearch
                        data={mapVehicleToSearch(props.vehicles as IVehicle[])}
                        getData={value => getVehicle(value)}
                        selectedData={mapSelectedVehicleToSelect()}
                        onClickItem={item => setVehicle(props.vehicles.find(it => it.id === item['id']))}
                        title={translate('mtr-manage.vehicle')}
                        style={{ backgroundColor: '#f6f6f6' }}
                        notAlphabeticalOrder
                        showInsiderSelect
                        showSearchField
                        filterLocalData
                        error={vehicleError.hasError}
                        errorMessage={translateErrorMessage(vehicleError.errorMessage)}
                    />
                </Col>
            );
        }
    };

    const renderResidueFinalDestination = (residue: IMtrResidue, index: number) => {
        if (
            mtr != null &&
            mtr.status != MtrStatus.COMPLETE &&
            mtr.status !== MtrStatus.CANCELLED &&
            !props.hasTransshipmentAuthorities &&
            !props.hasTransporterAuthorities &&
            !props.hasProducerAuthorities &&
            !props.hasManagerAuthorities
        ) {
            return (
                <div className="signature-generator__items--frame">
                    <Row style={{ marginBottom: '30px' }}>
                        <Col md={{ size: 5 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.total-wasteReceipt')}</div>
                            <div className="" style={{ marginTop: '-12px' }}>
                                <CustomTextField
                                    id={`amount-${index}`}
                                    onChange={(text: string) => updateAmountReceipt(index, text)}
                                    style={{ marginTop: '20px' }}
                                    value={residue.amountReceipt != null ? String(residue.amountReceipt).replace('.', ',') : ''}
                                    label={translate('mtr-manage.quantityReceipt')}
                                    placeholder={translate('mtr-manage.quantityReceipt')}
                                    error={amountError.get(index) ? amountError.get(index).hasError : null}
                                    errorText={amountError.get(index) ? translateErrorMessage(amountError.get(index).errorMessage) : ''}
                                />
                            </div>
                        </Col>
                        {/*
                        <Col md={{ size: 2 }} style={{ marginTop: '60px', maxWidth: '18%' }}>
                            <DropdownSearch
                                data={mapType()}
                                getData={search}
                                onClickItem={item => updateMeasurementType(index, item)}
                                selectedData={mapSelectedMeasurementTypeToSelect(index)}
                                title={translate('mtr-manage.measure')}
                                style={{ backgroundColor: '#f6f6f6' }}
                                notAlphabeticalOrder
                                showInsiderSelect
                                filterLocalData
                                showAllItems
                                showSearchField={false}
                                error={residueError.get(index) ? residueError.get(index).hasError : null}
                                errorMessage={residueError.get(index) ? translateErrorMessage(residueError.get(index).errorMessage) : ''}
                            />
                        </Col>
                        */}
                        <Col md={{ size: 9 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.justify')}</div>
                            <div className="" style={{ marginTop: '-12px' }}>
                                <CustomTextField
                                    id={`justification-${index}`}
                                    onChange={(text: string) => updateJustification(index, text)}
                                    style={{ marginTop: '10px' }}
                                    label={translate('mtr-manage.justify')}
                                    value={residue.justification}
                                    placeholder={translate('mtr-manage.justify')}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
    };

    const renderResidue = (residue: IMtrResidue, index: number) => {
        return (
            <div className="signature-generator__items--frame">
                {residue.sourceResidue != null ? (
                    renderMtrResidue(true, () => removeSourceResidue(index), residue.sourceResidue)
                ) : (
                    <Row>
                        <Col md={{ size: 6 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.specific-waste')}</div>
                            <div className="">
                                <DropdownSearch
                                    // data={mapSourceResiduesToSearch(props.sourceResidues as IVehicle[])}
                                    data={mapSourceResiduesToSearch(props.sourceResidues as ISourceResidueMtr[])}
                                    getData={value => getSourceResidues(value)}
                                    onClickItem={item => {
                                        props.getMtrResidue(item.id);
                                        setResidueIndex(index);
                                    }}
                                    title={translate('mtr-manage.residue')}
                                    style={{ backgroundColor: '#f6f6f6' }}
                                    notAlphabeticalOrder
                                    showInsiderSelect
                                    showSearchField
                                    filterLocalData
                                    error={residueError.get(index) ? residueError.get(index).hasError : null}
                                    errorMessage={residueError.get(index) ? translateErrorMessage(residueError.get(index).errorMessage) : ''}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
                <Row style={{ marginBottom: '30px' }}>
                    <Col md={{ size: 4 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.total-waste')}</div>
                        <div className="" style={{ marginTop: '-12px' }}>
                            <CustomTextField
                                id={`amount-${index}`}
                                onChange={(text: string) => updateAmount(index, text)}
                                style={{ marginTop: '20px' }}
                                value={residue.amount != null ? String(residue.amount).replace('.', ',') : ''}
                                label={translate('mtr-manage.quantity')}
                                placeholder={translate('mtr-manage.quantity')}
                                error={amountError.get(index) ? amountError.get(index).hasError : null}
                                errorText={amountError.get(index) ? translateErrorMessage(amountError.get(index).errorMessage) : ''}
                            />
                        </div>
                    </Col>
                    {/* <Col md={{ size: 5 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.total-wasteReceipt')}</div>
                        <div className="" style={{ marginTop: '-12px' }}>
                            <CustomTextField
                                id={`amount-${index}`}
                                onChange={(text: string) => updateAmountReceipt(index, text)}
                                style={{ marginTop: '20px' }}
                                value={residue.amountReceipt != null ? String(residue.amountReceipt).replace('.', ',') : ''}
                                label={translate('mtr-manage.quantityReceipt')}
                                placeholder={translate('mtr-manage.quantityReceipt')}
                                error={amountError.get(index) ? amountError.get(index).hasError : null}
                                errorText={amountError.get(index) ? translateErrorMessage(amountError.get(index).errorMessage) : ''}
                            />
                        </div>
                    </Col> */}
                    {/*
                    <Col md={{ size: 2 }} style={{ marginTop: '60px', maxWidth: '18%' }}>
                        <DropdownSearch
                            data={mapType()}
                            getData={search}
                            onClickItem={item => updateMeasurementType(index, item)}
                            selectedData={mapSelectedMeasurementTypeToSelect(index)}
                            title={translate('mtr-manage.measure')}
                            style={{ backgroundColor: '#f6f6f6' }}
                            notAlphabeticalOrder
                            showInsiderSelect
                            filterLocalData
                            showAllItems
                            showSearchField={false}
                            error={residueError.get(index) ? residueError.get(index).hasError : null}
                            errorMessage={residueError.get(index) ? translateErrorMessage(residueError.get(index).errorMessage) : ''}
                        />
                    </Col>
                    */}

                    {/* <Col md={{ size: 9 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.justify')}</div>
                        <div className="" style={{ marginTop: '-12px' }}>
                            <CustomTextField
                                id=''
                                style={{ marginTop: '10px' }}
                                label={translate('mtr-manage.justify')}
                                placeholder={translate('mtr-manage.justify')}
                            />
                        </div>
                    </Col> */}
                </Row>
            </div>
        );
    };

    const renderObservation = () => {
        const mtr = props.mtr;
        if (mtr != null && !editFlag) {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.observation')}</div>
                        <div className="list__items--component">
                            <div>
                                <div className="list__items--title">Observação</div>
                                <div className="list__items--text">{mtr?.observation}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                        <div className="signature-generator__items--list">{translate('mtr-signature.generator.observation')}</div>
                        <CustomTextFieldObservation
                            id=''
                            style={{ marginTop: '10px' }}
                            label={translate('mtr-manage.observation')}
                            placeholder={translate('mtr-manage.observation')}
                            value={mtr?.observation == null ? '' : mtr?.observation}
                            onChange={(text: string) => setObservation(text)}
                            maxLength={200}
                        />
                    </Col>
                </Row>
            );
        }
    };

    // const handleFilesAttached = (documents) => {
    //     setImageData(documents);
    // };

    const renderForm = () => {
        return (
            <div className="signature-generator__items" style={{ paddingTop: '39.5px' }}>
                <Container>
                    {renderProducerContent()}
                    {renderUnitContent()}
                    {renderTransporterContent()}
                    {renderUnitTransporterContent()}
                    <Row>
                        {renderDriverContent()}
                        {renderVehicleContent()}
                    </Row>
                    {renderTransshipmentContent()}
                    {renderUnitTransshipmentContent()}
                    {renderFinalDestinationContent()}
                    {renderUnitFinalDestinyContent()}
                    {residues?.map((it, index) => renderResidue(it, index))}
                    {residues?.map((it, index) => renderResidueFinalDestination(it, index))}
                    {residues?.findIndex(it => it.sourceResidue != null) > -1 && (transshipment?.id === undefined || (transshipment?.id != undefined && residues.length < 1)) && (
                        <Row>
                            <Col md={{ size: 10, offset: 1 }}>
                                <div className="signature-generator--component">
                                    <div className="signature-generator--component-img" onClick={addSourceResidue} />
                                    <div className="signature-generator--component-text">Adicionar mais resíduo</div>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {renderObservation()}
                    <Row style={{ marginBottom: '60px' }}>
                        <Col md={{ size: 5, offset: 1 }} style={{ paddingLeft: '36px', paddingRight: '2px' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.date')}</div>
                            <DatePickerComponent
                                dateFormat={APP_LOCAL_DATE_FORMAT}
                                onDateChange={value => {
                                    setCollectionDate(value);
                                }}
                                dateSelected={collectionDate}
                                placeholder={translate('mtr-manage.date')}
                                error={collectionDateError.hasError}
                                errorMessage={translateErrorMessage(collectionDateError.errorMessage)}
                            />
                        </Col>
                        <Col md={{ size: 5 }} style={{ paddingLeft: '36px', paddingRight: '2px', display: 'flex', alignItems: 'flex-end' }}>
                            {
                                //props.integrationUsers.length > 0 && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={integrationEnabled}
                                            onChange={(event) => setIntegrationEnabled(event.target.checked)}
                                            name={'integrationEnabled'}
                                            color={'primary'}
                                        />
                                    }
                                    label={translate('mtr-signature.generator.integrationEnabled')}
                                />
                                //)
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };

    const goToSignature = () => {
        props.history.push({
            pathname: `${props.match.url}/signature`,
            state: { id: mtr.id },
        });
    };

    const checkUserAuthorityAndSignature = () => {
        return !(
            mtr != null &&
            ((props.hasProducerUserAuthorities && mtr.producerSignature != null) ||
                (props.hasTransporterUserAuthorities && mtr.transporterSignature != null) ||
                (props.hasFinalDestinationUserAuthorities && mtr.finalDestinationSignature != null) ||
                (props.hasTransshipmentUserAuthorities && mtr.transshipmentSignature != null))
        );
    };

    const showSignButton = () => {
        if (
            !props.hasManagerAuthorities &&
            mtr != null &&
            mtr.status !== MtrStatus.CANCELLED &&
            mtr.status !== MtrStatus.INCOMPLETE &&
            mtr.status !== MtrStatus.COMPLETE &&
            !editFlag &&
            !props.hasFinalDestinationAuthorities &&
            ((mtr.producerSignature == null && props.hasProducerAuthorities) ||
                (mtr.transporterSignature == null && props.hasTransporterAuthorities) ||
                (mtr.producerSignature == null && props.hasProducerAuthorities) ||
                (mtr.transshipmentSignature == null && props.hasTransshipmentAuthorities) ||
                (mtr.finalDestinationSignature == null && props.hasFinalDestinationAuthorities) ||
                (mtr.finalDestinationSignature == null &&
                    props.hasFinalDestinationAuthorities &&
                    mtr.producerSignature != null &&
                    mtr.transporterSignature != null) ||
                (mtr.transshipmentSignature == null &&
                    props.hasTransshipmentAuthorities &&
                    mtr.producerSignature != null &&
                    mtr.finalDestinationSignature != null &&
                    mtr.transporterSignature != null))
        ) {
            return (
                <div className="pdf-button" onClick={() => goToSignature()}>
                    <div className="signature-generator__menu--signature">
                        <Translate contentKey={'mtr-signature.title'} />
                    </div>
                </div>
            );
        }
    };
    const showSignButtonReceipt = () => {
        if (!props.hasManagerAuthorities &&
            mtr != null &&
            mtr.status !== MtrStatus.CANCELLED &&
            mtr.status !== MtrStatus.INCOMPLETE &&
            mtr.status !== MtrStatus.COMPLETE &&
            !editFlag &&
            ((mtr.finalDestinationSignature == null && props.hasFinalDestinationAuthorities))
            // (mtr.finalDestinationSignature != null))
        ) {
            return (
                <div className="pdf-button" onClick={() => goToSignature()}>
                    <div className="signature-generator__menu--receipt">
                        <Translate contentKey={'mtr-signature.receipt-mtr'} />
                    </div>
                </div>
            );
        }
    };

    const showCancelButton = () => {
        if (viewModeOnly) {
            return;
        }
        if (
            mtr != null &&
            mtr.status != MtrStatus.COMPLETE &&
            !props.hasAnyUserAuthority &&
            !props.hasFinalDestinationAuthorities &&
            mtr.status !== MtrStatus.CANCELLED &&
            !props.hasTransshipmentAuthorities
        ) {
            return (
                <div
                    className="signature-generator__menu--cancel"
                    onClick={() => {
                        setMtrCancelId(mtr.id);
                        setCancelModal(true);
                    }}
                >
                    <Translate contentKey={'mtr-signature.cancel-mtr'} />
                </div>
            );
        }

        return;
    };

    const handlePdfIntegration = async () => {
        try {
            const manNumero = mtr.integrationNumberMtr
                ? mtr.integrationNumberMtr.toString()
                : mtr.integrationMtr
                    .filter(i => i.integrationMtrStatus === "FINISHED_MTR")
                    .map(it => it.integrationNumberMtr.toString())[0];

            const agency = mtr.agencyName
                ? mtr.agencyName.toString()
                : mtr.integrationMtr
                    .filter(i => i.integrationMtrStatus === "FINISHED_MTR")
                    .map(it => it.agency.toString())[0];


            let unidadeMtr;
            if (props.hasProducerAuthorities) {
                unidadeMtr = mtr?.producerUnit?.unitCode;
            }
            if (props.hasTransporterAuthorities) {
                unidadeMtr = mtr?.transporterUnit?.unitCode;
            }
            if (props.hasFinalDestinationAuthorities) {
                unidadeMtr = mtr?.finalDestinationUnit?.unitCode;
            }
            if (props.hasTransshipmentUserAuthorities) {
                unidadeMtr = mtr?.transshipmentUnit?.unitCode;
            }

            if (agency === "IMA") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.firstLogin
                const senha = targetUnit.password
                const cnpj = targetUnit.firstLogin
                const unidade = targetUnit.unit.unitCode
                // Construir a URL com os parâmetros de consulta dinamicamente
                const params = new URLSearchParams({
                    login,
                    senha,
                    cnpj,
                    unidade
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;

                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF IMA gerado com sucesso!")

            } else if (agency === "SINIR") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.secondLogin
                const senha = targetUnit.password
                const unidade = targetUnit.unit.unitCode

                const params = new URLSearchParams({
                    login,
                    senha,
                    unidade
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF SINIR gerado com sucesso!")

            } else if (agency === "SIGOR") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.secondLogin
                const senha = targetUnit.password
                const unidade = targetUnit.unit.unitCode

                const params = new URLSearchParams({
                    login,
                    senha,
                    unidade
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF SIGOR gerado com sucesso!")
            } else if (agency === "FEAM") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.secondLogin
                const cnpj = targetUnit.firstLogin
                const senha = targetUnit.password
                const unidade = targetUnit.unit.unitCode
                const unidadeTranspo = mtr.transporterUnit.unitCode
                const unidadeDestino = mtr.finalDestinationUnit.unitCode

                const params = new URLSearchParams({
                    login,
                    cnpj,
                    senha,
                    unidade,
                    unidadeTranspo,
                    unidadeDestino
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF FEAM gerado com sucesso!")
            } else if (agency === "FEPAM") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.secondLogin
                const cnpj = targetUnit.firstLogin
                const senha = targetUnit.password

                const params = new URLSearchParams({
                    login,
                    cnpj,
                    senha
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF FEPAM gerado com sucesso!")
            } else if (agency === "INEA") {
                const { integrationUsers } = props;
                const targetUnit = integrationUsers && integrationUsers.find(unit => unit.unit.unitCode === unidadeMtr);
                const login = targetUnit.secondLogin
                const cnpj = targetUnit.firstLogin
                const senha = targetUnit.password
                const unidade = targetUnit.unit.unitCode
                const unidadeTranspo = mtr.transporterUnit.unitCode
                const unidadeDestino = mtr.finalDestinationUnit.unitCode

                const params = new URLSearchParams({
                    login,
                    cnpj,
                    senha,
                    unidade,
                    unidadeTranspo,
                    unidadeDestino
                });

                const url = `/pdf-integracao/${manNumero}/${agency}?${params.toString()}`;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
                toast.success("PDF INEA gerado com sucesso!")
            }
        } catch (error) {
            console.error('Erro ao Pegar o PDF', error);
            toast.error('Sistema governamental está passando por instabilidades, tente novamente em instantes');
        }
    }

    const handleReceiveIntegrationStatus = async () => {
        try {
            setIsLoading(true);
            if (!mtr || !mtr.id) {
                console.error('MTR ID is missing');
                return;
            }
            const manNumero = mtr.id;

            // Altere a solicitação para uma solicitação GET e passe o ID do MTR diretamente na URL
            const response = await axios.get(`/consulta-mtr-status/${manNumero}`);
            console.log('Mtr status retrieved:', response.data);
        } catch (error) {
            console.error('Error fetching Mtr status:', error);
            // Handle errors appropriately (e.g., display an error message to the user)
        } finally {
            // Define um atraso de 10 segundos antes de definir isLoading como false
            setTimeout(() => {
                setIsLoading(false);
                window.location.reload();
            }, 10000); // 10000 milissegundos = 10 segundos
        }
    };

    const showReceiveIntegrationStatusButton = () => {
        if (
            mtr != null &&
            mtr.status != MtrStatus.COMPLETE &&
            mtr.status !== MtrStatus.CANCELLED
        ) {
            return (
                <div className="pdf-button" onClick={handleReceiveIntegrationStatus}>
                    {
                        mtr.integrationMtr != null &&
                        mtr.integrationMtr.filter(it => it.agency === "SINIR").map(it => {
                            return (
                                <div className="signature-generator__menu--signature">
                                    <div className="signature-generator__items--info" style={{ color: '#ffffff' }}>
                                        {translate('mtr-signature.integration-receive')} {it.agency}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            )
        }
    }

    const showEditButton = () => {
        if (viewModeOnly) {
            return;
        }
        let integrationMtrStatus = '';
        if (mtr && mtr.integrationEnabled && mtr.integrationMtr) {
            integrationMtrStatus = mtr.integrationMtr[0].integrationMtrStatus;
        }
        if (
            mtr != null &&
            !editFlag &&
            checkUserAuthorityAndSignature() &&
            mtr.status != MtrStatus.COMPLETE &&
            mtr.status !== MtrStatus.CANCELLED &&
            !props.hasFinalDestinationAuthorities &&
            integrationMtrStatus != IntegrationMtrStatus.FINISHED_MTR
        ) {
            return (
                <div className="signature-generator__menu--edit" onClick={() => setEditFlag(true)}>
                    <Translate contentKey={'mtr-signature.edit'} />
                </div>
            );
        }
    };

    const showEditReceiptButton = () => {
        // if (viewModeOnly) {
        //     return;
        // }

        if (
            mtr != null &&
            !editFlag &&
            checkUserAuthorityAndSignature() &&
            mtr.status != MtrStatus.COMPLETE &&
            mtr.status !== MtrStatus.CANCELLED &&
            (mtr.driver == null || mtr.driver != null) &&// Condição do motorista agrupada
            !props.hasTransshipmentAuthorities &&
            !props.hasTransporterAuthorities &&
            !props.hasProducerAuthorities &&
            !props.hasManagerAuthorities

        ) {
            return (
                <div className="signature-generator__menu--editReceipt" onClick={() => setEditFlag(true)}>
                    <Translate contentKey={'mtr-signature.receipt-edit'} />
                </div>
            );
        }
    };

    const showCreatePdfButton = () => {
        if (mtr != null && mtr.status !== MtrStatus.CANCELLED) {
            return (
                <div className="pdf-button" onClick={() => props.savePdf(mtr.id)}>
                    <div className="signature-generator__menu--generate">
                        <Translate contentKey={'mtr-signature.create-pdf'} />
                    </div>
                </div>
            );
        }
        return;
    };

    const showCreatePdfSinirButton = () => {
        if (mtr != null && mtr.status !== MtrStatus.CANCELLED && mtr.integrationEnabled == true) {
            return (
                <div className="pdf-button" onClick={handlePdfIntegration}>
                    {mtr.integrationMtr != null &&
                        mtr.integrationMtr
                            .filter(it => it.integrationMtrStatus == "FINISHED_MTR")
                            .map(it => {
                                return (
                                    <div className="signature-generator__menu--signature">
                                        {it.integrationNumberMtr &&
                                            <div className="signature-generator__items--info" style={{ color: '#ffffff' }}>
                                                {translate('mtr-signature.create-pdf')} {it.agency || mtr.agencyName}
                                            </div>}
                                    </div>
                                );
                            })}
                    {/* {mtr.integrationNumberMtr != null &&
                        <div className="signature-generator__menu--signature">
                            {mtr.integrationNumberMtr &&
                                <div className="signature-generator__items--info" style={{ color: '#ffffff' }}>
                                    {translate('mtr-signature.create-pdf')} {mtr.agencyName}
                                </div>}
                        </div>
                    } */}
                </div>
            );
        }
        return;
    };

    const renderModals = () => {
        return (
            <div>
                <AlertModal
                    showModal={showCancelModal}
                    buttonOneActionMessage={'mtr-signature.button-no'}
                    buttonTwoActionMessage={'mtr-signature.button-yes'}
                    hasTwoButtons
                    buttonTwoAction={() => {
                        props.cancelMtr(mtr.id);
                        props.history.goBack();
                        props.resetMtr();
                    }}
                    buttonOneAction={() => {
                        setCancelModal(false);
                        setMtrCancelId(null);
                    }}
                    statusImage="cancel-icon.svg"
                    alertMessage="modal-signature-generator"
                />

                {/* <AlertModal
                    showModal={showCancelSuccessModal}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                    hasOneButton
                    buttonTwoAction={() => {
                        props.history.goBack();
                        props.resetMtr();
                    }}
                    statusImage="cancel-icon2.svg"
                    alertMessage="modal-signature-generator-second"
                /> */}

                {/* <AlertModal
                    showModal={showCreateSuccessModal}
                    buttonTwoActionMessage={'mtr-signature.button-ok'}
                    hasOneButton
                    buttonTwoAction={() => {
                        props.resetMtr();
                        setCreateSuccessModal(false);
                        setEditFlag(false);
                    }}
                    statusImage="paper-ok.svg"
                    alertMessage="modal-create-success-ok"
                /> */}
            </div>
        );
    };

    const ShowSaveEdit = () => {
        if (viewModeOnly) {
            return;
        }
        if (
            mtr != null &&
            !editFlag &&
            mtr.status != MtrStatus.COMPLETE &&
            mtr.status !== MtrStatus.CANCELLED &&
            checkUserAuthorityAndSignature()
            // !props.hasFinalDestinationAuthorities &&
            // (mtr != null &&
            // props.hasFinalDestinationAuthorities &&
            // (mtr.producerSignature == null || mtr.transporterSignature == null))
        ) {
            return (
                <Button className={'button fourth button-fourth'} type={'submit'} onClick={() => saveMtr()}>
                    {mtr == null ? <Translate contentKey={'mtr-signature.new-mtr'} /> : <Translate contentKey={'mtr-signature.save-mtr'} />}
                </Button>
            )
        }
    }

    // const showSaveEditButton = () => {
    //     if (viewModeOnly) {
    //         return;
    //     }
    //     if (
    //         mtr != null &&
    //         !editFlag &&
    //         checkUserAuthorityAndSignature() &&
    //         mtr.status != MtrStatus.COMPLETE &&
    //         mtr.status !== MtrStatus.CANCELLED &&
    //         !props.hasTransshipmentAuthorities &&
    //         !props.hasTransporterAuthorities &&
    //         !props.hasProducerAuthorities &&
    //         !props.hasManagerAuthorities
    //     ) {
    //         return (
    //             <Button className={'button fourth button-fourth'} type={'submit'} onClick={() => saveMtr()}>
    //                 {<Translate contentKey={'mtr-signature.save-mtrEdit'} />}
    //             </Button>
    //         );
    //     }
    // };

    const mapType = () => {
        const objects = Object.keys(MeasurementType).map(key => {
            return { name: translate(`enums.measurementTypeAbbreviation.${key}`), keyEnum: key };
        });
        return objects.sort((a, b) => a.name.localeCompare(b.name));
    };

    const search = () => { };
    // @ts-ignore
    return (
        <div className={editFlag || mtr == null ? 'signature-generator-collapse' : 'signature-generator'}>
            {isLoading ? (
                <div className="loading-container">
                    <p>Aguarde</p>
                    <div className="loader"></div>
                    <p>VERIFICANDO O STATUS DA MTR NO SINIR</p>
                </div>
            ) : (
                <div className="signature-generator__menu">
                    <div className="signature-generator__menu--back">
                        <Container>
                            {renderModals()}
                            <Row>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    <div className="confirmation--back">
                                        <div className="confirmation--back-icon" />
                                        <span onClick={() => props.history.goBack()}>
                                            <Translate contentKey={'mtr-signature.back'} />
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                                        {mtr && mtr.status === MtrStatus.CANCELLED ? <div>{getMtrStatusStyle(mtr.status)}</div> : null}
                                        <div>{showCancelButton()}</div>
                                        {editFlag ? null : (
                                            <>
                                                <div>{showEditButton()}</div>
                                                <div>{showEditReceiptButton()}</div>
                                                <div>{showCreatePdfButton()}</div>
                                                <div>{showCreatePdfSinirButton()}</div>
                                            </>
                                        )}
                                        <div>{showReceiveIntegrationStatusButton()}</div>
                                        <div>{showSignButton()}</div>
                                        <div>{showSignButtonReceipt()}</div>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                    {editFlag || mtr == null ? (
                        <>
                            <div className="signature-generator__items" style={{ paddingBottom: '0px', display: 'flex', justifyContent: 'center' }} >
                                {renderMtrModelContent()}
                            </div>
                            {renderForm()}
                        </>
                    ) : mtr ? (
                        mtrView(
                            mtr,
                            () => setEditFlag(true),
                            () => {
                                setMtrCancelId(mtr.id);
                                setCancelModal(true);
                            },
                            open => setIsOpen(open),
                            isOpen
                        )
                    ) : (
                        <div />
                    )}
                </div>
            )}
            <Container>
                {isLoading ? (
                    <div></div>
                ) : (
                    <Row className="justify-center">
                        <Col md="3">
                            <div className="mtr-button-container">
                                {(mtr != null && (mtr.status === MtrStatus.CANCELLED || mtr.status === MtrStatus.COMPLETE)) ||
                                    // viewModeOnly ||
                                    (mtr != null &&
                                        props.hasManagerAuthorities &&
                                        (mtr.producerSignature == null || mtr.transporterSignature == null)
                                    ) ? (
                                    <div />
                                ) : (
                                    <Button className={'button fourth button-fourth'} type={'submit'} onClick={() => saveMtr()}>
                                        {mtr == null ? <Translate contentKey={'mtr-signature.new-mtr'} /> : <Translate contentKey={'mtr-signature.save-mtr'} />}
                                    </Button>
                                )}
                            </div>
                            {/*
                        <div className="mtr-button-container">{ShowSaveEdit()}</div> */}
                        </Col>
                    </Row>
                )}
            </Container>
            <AlertModalPlanRestricted closeModal={() => closeModal()}
                showModal={showModalPlan}
                title='exccededMtr'
                description={props?.saveMtrErrorMessage}
                premium={false}
                history={() => history.push('/full-register/plans')}>
            </AlertModalPlanRestricted>
        </div>
    );
};

const mapStateToProps = (root: IRootState) => ({
    mtr: root.mtr.mtr,
    saveMtrErrorMessage: root.mtr.saveMtrErrorMessage,
    units: root.producerUnits.units,
    unitsProducer: root.producerUnits.unitsProducer,
    unitsFinalDestination: root.producerUnits.unitsFinalDestination,
    unitsTransporter: root.producerUnits.unitsTransporter,
    unitsTransshipment: root.producerUnits.unitsTransshipment,
    mtrModels: root.mtrModel.mtrModelList,
    getMtrStatus: root.mtr.getMtr,
    drivers: root.driver.drivers,
    producer: root.mtr.mtrProducer,
    residue: root.mtr.mtrResidue,
    saveMtrStatus: root.mtr.saveMtr,
    savedMtr: root.mtr.savedMtr,
    cancelMtrStatus: root.mtr.cancelMtrStatus,
    sourceResidues: root.sourceResidue.sourceResidues,
    vehicles: root.vehicle.vehicles,
    totalItems: root.mtr.totalItems,
    producers: root.producer.producers,
    transporter: root.mtr.mtrTransporter,
    transporters: root.transport.transporters,
    transshipment: root.mtr.mtrTransshipment,
    transshipments: root.transshipment.transshipments,
    finalDestination: root.mtr.mtrFinalDestination,
    finalDestinations: root.finalDestination.finalDestinations,
    hasTransporterAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSPORTER_ADMIN,
        AUTHORITIES.TRANSPORTER_USER,
    ]),
    hasTransshipmentAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.TRANSSHIPMENT_ADMIN,
        AUTHORITIES.TRANSSHIPMENT_USER,
    ]),
    hasProducerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_ADMIN, AUTHORITIES.PRODUCER_USER]),
    hasFinalDestinationAuthorities: hasAnyAuthority(root.authentication.account.authorities, [
        AUTHORITIES.FINAL_DESTINATION_ADMIN,
        AUTHORITIES.FINAL_DESTINATION_USER,
    ]),
    hasManagerAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_ADMIN, AUTHORITIES.MANAGER_USER]),
    hasAnyUserAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_USER_AUTHORITIES),
    hasProducerUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.PRODUCER_USER]),
    hasManagerUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.MANAGER_USER]),
    hasTransporterUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSPORTER_USER]),
    hasTransshipmentUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.TRANSSHIPMENT_USER]),
    hasFinalDestinationUserAuthorities: hasAnyAuthority(root.authentication.account.authorities, [AUTHORITIES.FINAL_DESTINATION_USER]),
    integrationUsers: root.integrationUser.integrationUsers,
    profiles: root.profile.profiles,
});

const mapDispatchToProps = {
    searchFinalDestinations,
    getMtrFinalDestination,
    searchTransshipments,
    getMtrTransshipment,
    searchSourceResidues,
    searchTransporters,
    getMtrTransporter,
    getSubsidiariesByHolding: getIntegrationUnits,
    getIntegrationUnitsTransporter,
    getIntegrationUnitsProducer,
    getIntegrationUnitsFinalDestiny,
    getIntegrationUnitsTransshipment,
    resetTransporters,
    searchProducers,
    getMtrProducer,
    searchVehicles,
    resetProducers,
    searchDrivers,
    getMtrResidue,
    resetResidues,
    resetVehicles,
    resetDrivers,
    resetStatus,
    cancelMtr,
    resetMtr,
    saveMtr,
    savePdf,
    findMtr,
    getMtrModels,
    getMtrs,
    getIntegrationUsers,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrManage);
