import { IMtrResidue } from 'app/shared/model/mtr-residue.model';
import { ISpecificResidue } from './specific-residue.model';
import { ISignature } from './signature.model';
import { IFinalDestination, IFinalDestinationMtr } from './final-destination.model';
import { IProducer, IProducerMtr } from './producer.model';
import { IVehicle } from './vehicle.model';
import { IDriver } from './driver.model';
import { ITransporter, ITransporterMtr } from 'app/shared/model/transporter.model';
import { MeasurementType } from './authorization.model';
import { ITransportLicense } from './transport-license.model';
import { IEnvironmentalLicense } from './environmental-license.model';
import { Transshipment, ITransshipmentMtr } from 'app/shared/model/transshipment.model';
import { StorageLicense } from 'app/shared/model/storage-license.model';
import { IIntegrationQueue } from './integration-queue.model';
import { IIntegrationMtr } from './integration-mtr.model';
import { IUnit, ProducerUnit } from './unit';

export enum MtrStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
}

export enum IntegrationMtrStatus {
  ERROR = 'ERROR',
  DENIED = 'DENIED',
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED_ALL = 'FINISHED_ALL',
  FINISHED_MTR = 'FINISHED_MTR',
  INCOMPLETE_TO_INTEGRATION = 'INCOMPLETE_TO_INTEGRATION',
}

export interface IMtr {
  id?: number;
  numberMtr?: string;
  producer?: IProducer & IProducerMtr;
  producerUnit?: IUnit;
  finalDestinationUnit?: IUnit;
  transporterUnit?: IUnit;
  transporter?: ITransporter & ITransporterMtr;
  vehicle?: IVehicle;
  driver?: IDriver;
  finalDestination?: IFinalDestination & IFinalDestinationMtr;
  transshipment?: Transshipment & ITransshipmentMtr;
  transshipmentUnit?: IUnit;
  collectionDate?: string;
  producerSignature?: ISignature;
  producerSignatureDate?: string;
  transporterSignature?: ISignature;
  transporterSignatureDate?: string;
  transshipmentSignature?: ISignature;
  integrationNumberMtr?: string;
  transshipmentSignatureDate?: string;
  finalDestinationSignature?: ISignature;
  finalDestinationSignatureDate?: string;
  mtrResidues?: IMtrResidue[];
  status?: MtrStatus;
  integrationMtr?: IIntegrationMtr[];
  integrationQueue?: IIntegrationQueue;
  integrationEnabled?: boolean;
  integrationMtrStatus?: IntegrationMtrStatus;
  linkIntegration?: string;
  finalDestinationLicense?: IEnvironmentalLicense;
  transshipmentLicense?: StorageLicense;
  transportLicense?: ITransportLicense;
  viewModeOnly?: boolean;
  observation?: string;
  imagesData?: Document;
  driverName?: string;
  vehicleLicensePlate?: string;
  parCodigoTransportador?: number;
  parDescricaoTransportador?: string;
  parCodigoDestino?: number;
  parDescricaoDestino?: string;
  parCodigoGerador?: number;
  parDescricaoGerador?: string;
  agencyName?: string;
}

export interface IResidueMtr {
  id?: number;
  code?: string;
  description?: string;
  residueType?: string;
  residueSubcategory?: string;
  measurementType?: MeasurementType;
  specificResidue?: ISpecificResidue;
  descriptionResidue?: string;
  amountResidue?: number;
  amountReceiptResidue?: number;
  measurementTypeResidue?: string;
}

export const defaultValue: Readonly<IMtr> = {};
