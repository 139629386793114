import DeleteEditButton from '../../../components/delete-edit-button/delete-edit-button';
import { ColumnSort, SimpleOrderedTable } from '../../../components/simple-ordered-table/simple-ordered-table';
import SimpleSearchField from '../../../components/simple-search-field/simple-search-field';
import { createMtrModel, getMtrModel, updateMtrModel, deleteMtrModel, getMtrModels, reset } from './mtr-model.reducer' //update
import { HttpRequestStatus } from '../../../shared/model/enum/HttpRequestStatus';
import { Pageable } from '../../../shared/model/pageable';
import { IRootState } from '../../../shared/reducers';
import StringUtils from '../../../shared/util/string-utils';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { default as Button, default as Col } from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import moment from 'moment';
import { IAuthorization } from '../../../shared/model/authorization.model';
import { ALL_ADMIN_AUTHORITIES } from '../../../config/constants';
import { hasAnyAuthority } from '../../../shared/auth/private-route';

export interface IMtrModelListProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export interface IMtrModelListState {
  filterName: string;
  page: Pageable;
}

export class MtrModelList extends React.Component<IMtrModelListProps, IMtrModelListState> {
  timer = null;
  private tableHeaders: ColumnSort[] = [
    // Nome do modelo
    { name: translate('mtrModel.table.name'), sortCode: 'name' },

    { name: translate('mtrModel.table.author'), sortCode: 'author' },
    { sortDisabled: true },
  ];

  constructor(props: Readonly<IMtrModelListProps>) {
    super(props);
    this.state = {
      filterName: '',
      page: { size: 10, page: 0, totalElements: 0, totalPages: 0 },
    };
  }

  async componentDidMount() {
    this.props.reset();
    this.getMtrModels();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.deleteStatus === HttpRequestStatus.SUCCESS) {
      this.props.reset();
      this.props.getMtrModels();
    }
    if (newProps.totalItems !== this.props.totalItems) {
      this.setState(prevState => ({
        page: {
          ...prevState.page,
          totalElements: newProps.totalItems,
        },
      }));
    }
  }

  getMtrModels = () => {
    const { page } = this.state;
    this.props.getMtrModels(
      page.page,
      page.size,
      page.sort,
      StringUtils.toObject('name', this.state.filterName)
    );
  };

  private handleTransformToTableContent = (content): (string | JSX.Element)[][] => {
    if (content == null || content.length === 0) {
      return [];
    }
    const result: (string | JSX.Element)[][] = [];
    console.log(content);
    content.map((item) => {
      result.push([
        item.name,
        item.expirationDate ? moment(item.expirationDate).format('DD/MM/YYYY') : '',
        <DeleteEditButton
          id={item.id}
          onDelete={this.onDeleteMtrModel}
          onEdit={this.onEditMtrModel}
          hideDelete={!this.props.hasAnyAdminAuthority}
          hideEdit={!this.props.hasAnyAdminAuthority}
        />,
      ]);
    });

    return result;
  };

  onDeleteMtrModel = (id: number) => {
    this.props.deleteMtrModel(id);
  };

  onCreateMtrModel = () => {
    this.props.history.push(`${this.props.match.url}/new`);
  };

  onEditMtrModel = (id: number) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/edit`,
      state: { id: id },
    });
  };

  private handleSort = (code: string) => {
    console.log(code)
    this.setState(
      {
        page: { ...this.state.page, sort: code },
      },
      () => this.getMtrModels()
    );
  };

  private handleChangePage = (newPage: number) => {
    this.setState(
      {
        page: { ...this.state.page, page: newPage },
      },
      () => this.getMtrModels()
    );
  };

  onHandleFilterChanged = value => {
    console.log(value);
    this.setState({ filterName: value }, () => this.getMtrModels());
  };

  onMtrModelClick = (index: number) => {
    const { mtrModelList } = this.props;

    const mtrModel = mtrModelList[index];
    this.props.history.push({
      pathname: `${this.props.match.url}/details`,
      state: { id: mtrModel.id },
    });
  };


  render() {
    const { mtrModelList, hasAnyAdminAuthority } = this.props;
    const rows = this.handleTransformToTableContent(mtrModelList);
    return (
      <div className="mtr-unity__items mtr-unity__authorization">
        <Container>
          <Row>
            <Col style={{ margin: 'auto' }} md="12">
              <h1 style={{ textAlign: 'left', marginTop: '28px', fontWeight: 600 }}>{translate('mtrModel.title')}</h1>
            </Col>
          </Row>
          <Row style={{ marginBottom: '27px' }}>
            <Col md="6">
              <SimpleSearchField placeholder={translate('mtrModel.search')} onChange={this.onHandleFilterChanged} />
            </Col>
            {hasAnyAdminAuthority && (
              <Col md={{ size: 2, offset: 4 }}>
                <Button className={'button primary'} type={'submit'} onClick={this.onCreateMtrModel}>
                  <Translate contentKey={'mtrModel.add'} />
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <SimpleOrderedTable
                rows={rows}
                columNameKeys={this.tableHeaders}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onSort={(code: string) => this.handleSort(code)}
                onClick={this.onMtrModelClick}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (root: IRootState) => ({
  totalItems: root.mtrModel.totalItems,
  hasAnyAdminAuthority: hasAnyAuthority(root.authentication.account.authorities, ALL_ADMIN_AUTHORITIES),
  mtrModelList: root.mtrModel.mtrModelList,
  mtrModel: root.mtrModel.mtrModel,
  getMtrModelListStatus: root.mtrModel.getMtrModelListStatus,
  getMtrModeltatus: root.mtrModel.getMtrModeltatus,
  createMtrModelStatus: root.mtrModel.createMtrModeltatus,
  updateMtrModelStatus: root.mtrModel.updateMtrModelStatus,
  deleteMtrModelStatus: root.mtrModel.deleteMtrModelStatus,
});

const mapDispatchToProps = {
  createMtrModel,
  getMtrModel,
  updateMtrModel,
  deleteMtrModel,
  getMtrModels,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrModelList);
