import React, { Fragment, useEffect } from 'react';
import { translate } from 'react-jhipster';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { IMtr, MtrStatus } from '../../../../shared/model/mtr.model';
import { APP_LOCAL_DATE_FORMAT, APP_ONLY_DATE_FORMAT } from '../../../../config/constants';
import moment from 'moment';
import { PersonType } from '../../../../shared/model/transporter.model';
import { maskCnpj, maskZipCode } from '../../../../shared/util/mask-utils';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import './mtr-model-view.scss';

export interface IMtrModelCreateProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
    modelName: string;
    mtr?: IMtr
}

const MtrModelView = ({ mtr, modelName }): JSX.Element => {
    const producer = mtr.producer ?? {};
    const unit = mtr.unitProducer ?? {};
    const unitFinalDestiny = mtr.unitFinalDestiny ?? {}
    const unitTransporter = mtr.unitTransporter ?? {}
    const unitTransshipment = mtr.unitTransshipment ?? {}
    const transporter = mtr.transporter ?? {};
    const transshipment = mtr.transshipment ?? {};
    const finalDestination = mtr.finalDestination ?? {};
    const transportLicenses =
        mtr.transporter?.transportLicenses[0] ?? {};
    const environmentalLicense =
        mtr.finalDestination?.environmentalLicenses[0] ?? {};
    const storageLicense =
        mtr.transshipment?.storageLicenses[0] ?? {};

    return (
        <div className="signature-generator__items">
            {/* {mtr.integrationQueue != null && mtr.integrationQueue?.errorDescription != null && <div style={{ marginLeft: '150px', marginBottom: '20px' }}>
                <Row>
                    <Col md={{ size: 11 }} style={{ paddingRight: '55px' }}>
                        <Alert severity="error">{`${translate('mtr-signature.integrationError')}${mtr.integrationQueue?.errorDescription}`}</Alert>
                    </Col>
                </Row>
            </div>} */}

            <Container>
                <Row style={{ marginTop: '20px' }}>
                    <Col md={{ size: 4, offset: 1 }}>
                        <div className="signature-generator__items--title">
                            <div style={{ display: 'flex' }}>
                                <div className="signature-generator__items--title-text">
                                    <span>{translate('mtr-signature.generator.title')}</span>
                                    <span style={{ fontWeight: 300, color: 'black' }}>{translate('mtr-signature.generator.title-second')}</span>
                                    <span>{translate('mtr-signature.generator.title-third')}</span>
                                    <div style={{ width: '1px', height: '31px', border: 'solid 1px #aaaaaa' }} />
                                </div>
                                <div className="signature-generator__items--title-img" />
                            </div>
                            <div className="signature-generator__items--title-description">{translate('mtr-signature.generator.description')}</div>
                        </div>
                    </Col>
                    <Col md={{ size: 4 }}>
                    </Col>
                    <Col >
                        <div className="signature-generator__items--info">
                            {translate('mtrModel.modelName')}
                            <span>{modelName}</span>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.generator')}</div>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.companyName')}</div>
                                <div className="list__items--text">{producer.legalPerson?.fantasyName?.toUpperCase() ?? producer.naturalPerson?.name?.toUpperCase() ?? ''}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }} >
                                <div className="list__items--title">{translate('mtr.address')}</div>
                                <div className="list__items--text">{producer.addressLabel ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                                <div className="list__items--text">{producer?.address?.zipcode != null ? maskZipCode(producer.address.zipcode) : ''}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                                    <div className="list__items--text">{unit.name?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                                    <div className="list__items--text">{unit.unitCode?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.transporter')}</div>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                {transporter.personType === PersonType.NATURAL ? (
                                    <>
                                        <div className="list__items--title">Nome completo</div>
                                        <div className="list__items--text">{transporter.name?.toUpperCase() ?? ''}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="list__items--title">{translate('mtr.companyName')}</div>
                                        <div className="list__items--text">{transporter.legalPerson?.companyName?.toUpperCase() ?? ''}</div>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.address')}</div>
                                <div className="list__items--text">{transporter.addressLabel ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                                <div className="list__items--text">{transporter?.address?.zipcode != null ? maskZipCode(transporter.address.zipcode) : ''}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 2, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.transportLicense')}</div>
                                <div className="list__items--text">{transportLicenses?.licenseNumber ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="5" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                                <div className="list__items--text">{transportLicenses.issuingBody?.toUpperCase() ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                                <div className="list__items--text">
                                    {transportLicenses.expireDate != null ? moment(transportLicenses.expireDate).format(APP_LOCAL_DATE_FORMAT) : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                                    <div className="list__items--text">{unitTransporter.name?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                                    <div className="list__items--text">{unitTransporter.unitCode?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {!isEmpty(transshipment) && (
                        <Fragment>
                            <Row>
                                <Col md={{ size: 5, offset: 1 }} style={{ paddingRight: '0' }}>
                                    <div className="signature-generator__items--list">{translate('mtr-signature.generator.transshipment')}</div>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.companyName')}</div>
                                        <div className="list__items--text">{transshipment.companyName?.toUpperCase() ?? ''}</div>
                                    </div>
                                </Col>
                                <Col md="5" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.cnpj')}</div>
                                        <div className="list__items--text">{transshipment.cnpj ? maskCnpj(transshipment.cnpj) : ''}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.address')}</div>
                                        <div className="list__items--text">{transshipment.addressLabel ?? ''}</div>
                                    </div>
                                </Col>
                                <Col md="3" style={{ paddingLeft: '2px' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.zipcode')}</div>
                                        <div className="list__items--text">{transshipment?.address?.zipcode != null ? maskZipCode(transshipment.address.zipcode) : ''}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                                        <div className="list__items--text">{storageLicense.licenseNumber ?? ''}</div>
                                    </div>
                                </Col>
                                <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                                        <div className="list__items--text">{storageLicense.issuingBody?.toUpperCase() ?? ''}</div>
                                    </div>
                                </Col>
                                <Col md="3" style={{ paddingLeft: '2px' }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.type')}</div>
                                        <div className="list__items--text">
                                            {storageLicense.storageLicenseType ?? ''}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 10, offset: 1 }}>
                                    <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                        <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                                        <div className="list__items--text">
                                            {storageLicense.expireDate != null ? moment(storageLicense.expireDate).format('DD/MM/YYYY') : ''}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                                    <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                                    <div className="list__items--component">
                                        <div>
                                            <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                                            <div className="list__items--text">{unitTransshipment.name?.toUpperCase() ?? ''}</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                                    <div className="list__items--component">
                                        <div>
                                            <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                                            <div className="list__items--text">{unitTransshipment.unitCode?.toUpperCase() ?? ''}</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                    <Row>
                        <Col md={{ size: 10, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="signature-generator__items--list">{translate('mtr-signature.generator.final-destiny')}</div>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.companyName')}</div>
                                <div className="list__items--text">{finalDestination.legalPerson?.fantasyName?.toUpperCase() ?? finalDestination.naturalPerson?.name?.toUpperCase() ?? ''}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.finalDestinationCategory')}</div>
                                <div className="list__items--text">
                                    {finalDestination.finalDestinationCategory
                                        ? translate(`enums.finalDestinationCategory.${finalDestination.finalDestinationCategory}`)
                                        : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.address')}</div>
                                <div className="list__items--text">{finalDestination.addressLabel ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.zipcode')}</div>
                                <div className="list__items--text">{finalDestination?.address?.zipcode != null ? maskZipCode(finalDestination.address.zipcode) : ''}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 4, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.licenseNumber')}</div>
                                <div className="list__items--text">{environmentalLicense.licenseNumber ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px', paddingRight: '0' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.issuingBody')}</div>
                                <div className="list__items--text">{environmentalLicense.issuingBody?.toUpperCase() ?? ''}</div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px' }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">Tipo</div>
                                <div className="list__items--text">
                                    {environmentalLicense.environmentalLicenseType != null
                                        ? translate(`enums.environmentalLicenseType.${environmentalLicense.environmentalLicenseType}`)
                                        : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                            <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                <div className="list__items--title">{translate('mtr.expirationDate')}</div>
                                <div className="list__items--text">
                                    {environmentalLicense.expireDate != null ? moment(environmentalLicense.expireDate).format('DD/MM/YYYY') : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 7, offset: 1 }} style={{ paddingRight: '0' }}>
                            <div className="signature-generator__items--list">{translate('mtr-units.title')}</div>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.fantasyName')}</div>
                                    <div className="list__items--text">{unitFinalDestiny.name?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md="3" style={{ paddingLeft: '2px', marginTop: '52px' }}>
                            <div className="list__items--component">
                                <div>
                                    <div className="list__items--title">{translate('mtr-units.unitCode')}</div>
                                    <div className="list__items--text">{unitFinalDestiny.unitCode?.toUpperCase() ?? ''}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {mtr.mtrResidues != null &&
                        mtr.mtrResidues
                            .map(it => {
                                const residue = it.sourceResidue;
                                const name = residue?.specificResidue?.name ?? '';
                                const description = residue?.description ?? '';
                                const measure = it.measurementType ?? it.sourceResidue?.measurementType;
                                const measurementType = residue != null ? translate(`enums.measurementTypeAbbreviation.${measure}`) : '';
                                return (
                                    <div>
                                        <Row>
                                            <Col md={{ size: 10, offset: 1 }}>
                                                <div className="signature-generator__items--list">{translate('mtr-signature.generator.waste')}</div>
                                                <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                                    <div className="list__items--title">{translate('mtr.residueDescription')}</div>
                                                    <div className="list__items--text">{description}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '30px' }}>
                                            <Col md={{ size: 6, offset: 1 }} style={{ paddingRight: '0' }}>
                                                <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                                    <div className="list__items--title">{translate('mtr.specificResidue')}</div>
                                                    <div className="list__items--text">{name}</div>
                                                </div>
                                            </Col>
                                            <Col md={{ size: 2 }} style={{ paddingRight: '0', paddingLeft: '2px' }}>
                                                <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                                    <div className="list__items--title">{translate('mtr.amountResidue')}</div>
                                                    <div className="list__items--text">{`${it.amount ?? ''} ${measurementType}`}</div>
                                                </div>
                                            </Col>
                                            <Col md={{ size: 2 }} style={{ paddingLeft: '2px' }}>
                                                <div className="list__items--component" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '5px 15px' }}>
                                                    <div className="list__items--title">{translate('mtr.amountResidueReceipt')}</div>
                                                    <div className="list__items--text">{`${it.amountReceipt ?? ''} ${measurementType}`}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                </div>

            </Container>
        </div>
    );
};

const mapStateToProps = (root: IRootState) => ({

});

const mapDispatchToProps = {

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MtrModelView);
